.multiplayer-cursor {
  position: absolute;
  top: -2px;
  width: 16px;
  height: 16px;
  z-index: 9999;
  border-radius: 999px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  pointer-events: none;
  transition: opacity 300ms ease-out;
  opacity: 1;
  padding: 0;
  margin: 0;
  background: transparent;
}
.multiplayer-cursor.sleeping {
  opacity: 0;
}
.multiplayer-cursor .nametag {
  width: auto;
  font-size: 10px;
  position: fixed;
  white-space: nowrap;
  display: inline-block;
  padding: 1px 8px;
  border-radius: 99px;
  margin-top: 0px;
  margin-left: 5px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.1);
}
