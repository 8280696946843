body {
  overflow-y: auto !important;
  height: 100% !important;
  min-height: 100vh !important;
  counter-reset: slide;
}

.Cards {
  .CodeMirror {
    // height: 100% !important;
  }
}

// .height100 {
//   height: 100% !important;
// }

.spacer {
  height: calc(50vh - 120px);
}

.focusModeEditor,
.focusModeEditor > * {
  background-color: transparent !important;
}

.focusModeEditor .linkBlock {
  height: 80vh;
  width: 90%;
  transition: width 150ms ease-out, height 150ms ease-out;
}

.isActive .focusModeEditor .linkBlock {
  width: 100%;
  transform: none;
}

.backdrop.zoomed {
  filter: blur(10px) brightness(50%);
}

.backdrop {
  transition: filter 250ms ease-out;
}

.imageViewer {
  pointer-events: none;
  background-color: transparent;
  transition: background-color 800ms cubic-bezier(0.33, 1, 0.68, 1);
}

[contenteditable][placeholder]:empty::before {
  content: attr(placeholder);
  color: #ccc;
}

.Teleprompter {
  transition: transform 800ms cubic-bezier(0.33, 1, 0.68, 1);
  color: white;

  .slide {
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    &:last-of-type {
      margin-bottom: 25vh;
    }

    *::selection {
      color: white !important;
      background: #000;
    }
  }

  .deadStep {
    display: none;
  }

  p:not(.chakra-text) {
    font-size: 30px;
  }

  h1.title {
    font-size: 90px;
  }

  h1 {
    font-size: 74px;
  }

  h2 {
    font-size: 58px;
  }

  h3 {
    font-size: 46px;
  }

  h4 {
    font-size: 37px;
  }

  .step {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin: 0px;
    transform-origin: left;
    transition: all 250ms ease-in-out;
    opacity: 0.25;

    &.isActive:not(.react-renderer) {
      opacity: 1;
      transform: scale(1.06);

      a {
        color: #62b2fd;
      }
    }

    .embed {
      width: 80vw;
      height: 80vh;
      border: 0;
      margin-left: -8rem;

      .browser {
        background: none;
      }
    }
  }

  .canva {
    img {
      width: 80vw;
      height: 80vh;
    }
  }

  li {
    list-style: none;
  }
  li > p::before {
    content: '•';
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    color: var(--chakra-colors-gray-900);
    border-left: 0 !important;
    padding: 0 !important;
    margin: 0 !important;

    &:empty {
      display: none;
    }

    > p {
      transition: border 150ms ease-out;
      border-left: 2px solid gray;
      padding-left: 1.5em;
      padding-right: 1.5em;
      &.isActive {
        border-left-color: var(--chakra-colors-blue-500) !important;
      }
    }
  }

  [data-type='taskList'] label {
    padding-top: 1.75rem;
  }

  input[type='checkbox'] {
    transform: scale(2.5);
    margin-right: 1.25em;
    left: 10px;
    position: relative;
    top: 3px;
  }
}

body.zoom .slide {
  background-color: #000;
}
body.zoom .slide * {
  color: #000 !important;
}
body.slide img {
  transition: opacity 800ms cubic-bezier(0.33, 1, 0.68, 1);
}
body.zoom .slide img {
  opacity: 0;
}

body.zoom .backdrop {
  opacity: 0;
}

#focus-mode {
  .slide:last-of-type {
    margin-bottom: 25vh;
  }

  .full-width-slide {
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    .text {
      padding: 0px;
    }
    img {
      border-radius: 12px;
      box-shadow: var(--chakra-shadows-lg);
    }
    margin-left: 2em;
  }

  ul,
  ol {
    padding-top: 0;
  }

  .tocList {
    transition: -webkit-mask-image 250ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .tocList.doesScroll[data-scroll-point='top'] {
    -webkit-mask-image: -webkit-gradient(
      linear,
      left 90%,
      left bottom,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
  }
  .tocList.doesScroll[data-scroll-point='middle'] {
    -webkit-mask-image: -webkit-repeating-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 10%,
      rgb(0, 0, 0) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .tocList.doesScroll[data-scroll-point='bottom'] {
    -webkit-mask-image: -webkit-gradient(
      linear,
      left 10%,
      left top,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
  }

  .tocWrapper {
    transition: all 250ms cubic-bezier(0.33, 1, 0.68, 1);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .arrow {
    transition: all 250ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .tocWrapper.is-active .arrow {
    transform: translate(240px, 0px);
  }

  .tocList {
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .tocBackdrop {
    transition: opacity 250ms cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;
  }
  .tocWrapper.is-active .tocBackdrop {
    opacity: 1;
  }

  .tocWrapper.is-active .tocList {
    opacity: 1;
  }
  .cardsWrapper {
    transition: transform 250ms cubic-bezier(0.33, 1, 0.68, 1);
  }
  .tocWrapper.is-active + .cardsWrapper,
  .tocWrapper.is-active + .preview-wrapper {
    transform: translate(0, 0);
  }
}

.imageViewer {
  pointer-events: none;
  background-color: transparent;
  transition: background-color 250ms cubic-bezier(0.33, 1, 0.68, 1);
}
.imageViewer.zoom {
  background-color: rgba(0, 0, 0, 0.9);
}

.imageViewer .image {
  transition: all 250ms cubic-bezier(0.33, 1, 0.68, 1);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.imageViewer.zoom .image {
  left: 0px !important;
  right: 0px !important;

  width: calc(100%) !important;
  height: calc(100%) !important;
}

#edit-mode {
  .editor-slide {
    &::before {
      padding: 2px 9px;
      background-color: var(--chakra-colors-gray-200);
      color: var(--chakra-colors-gray-400);
      border-radius: 5px;
      text-align: center;
      counter-increment: slide;
      content: counter(slide);
      position: absolute;
      left: -40px;
      top: -10px;
      font-size: 11px;
      font-weight: bold;
      opacity: 1;
    }
  }

  .title.editor-slide::before {
    left: -84px;
    top: -46px;
    padding: 3px 9px;
  }

  .full-width-slide.editor-slide::before {
    left: -90px;
    top: 0px;
  }

  .embed.editor-slide {
    overflow: visible;
  }

  .embed.zoomed {
    position: fixed;
    top: 3em;
    left: 7em;
    height: 97vh !important;
    width: 98vw !important;
  }
}
