.lobby {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;

  &.presenting .backdrop,
  &.joining .backdrop {
    filter: blur(5px);
  }

  .backdrop {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;
    background-position: center;
    background-size: cover;
    transition: opacity 350ms ease-in, filter 1s ease-in-out,
      transform 2s ease-out;
  }

  .controls {
    > div > * {
      margin-left: 8px;
    }
  }

  .chakra-popover__content,
  .chakra-popover__arrow {
    background-color: rgb(0 0 0 / 70%);
    backdrop-filter: blur(20px);
  }

  .chakra-menu__menu-list {
    & {
      background-color: rgb(0 0 0 / 59%);
      backdrop-filter: blur(20px);
      border: none;
      color: #eee;
    }
    .chakra-menu__menuitem {
      background-color: rgb(80 80 80 / 0);
    }
    .chakra-menu__menuitem:hover {
      background-color: rgb(80 80 80 / 59%);
    }
    .chakra-menu__divider {
      border-color: rgb(80 80 80 / 59%);
    }
    .chakra-menu__group__title {
      font-size: 11px;
      text-transform: uppercase;
      color: #aaa;
      margin-left: 0.8rem;
    }
  }

  .background-image-picker {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: left;
    line-height: normal;
    color: white;
    outline: none;
  }

  .background-image-picker .image-grid {
    display: grid;
    grid-auto-rows: 74px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-gap: 16px;
    padding: 16px;
    padding-top: 8px;
    overflow: auto;
    height: calc(100% - 88px);
    padding-bottom: 32px;
  }

  .background-image-container {
    border-radius: 10px;
    transition: transform 250ms cubic-bezier(0.34, 1.56, 0.64, 1);

    .img {
      border-radius: 10px;
      cursor: pointer;
    }

    .img-caption {
      display: block;
      position: relative;
      font-size: 12px;
      bottom: 0px;
      padding: 5px 10px;
      left: 0px;
      right: 0px;
      top: -25px;
      background: linear-gradient(
        0deg,
        rgb(0 0 0 / 59%) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      transition: opacity 250ms ease-out;
      opacity: 0;
      left: 0px !important;
      bottom: 0px !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:hover {
      transform: scale(1.1);
      .img-caption {
        opacity: 1;
      }
    }
  }

  .notification-dot {
    background-color: tomato;
    width: 12px;
    height: 12px;
    position: absolute;
    border: 1px solid #222;
    border-radius: 999px;
    right: 0px;
    top: 0px;
    transition: opacity 250ms cubic-bezier(0.34, 1.56, 0.64, 1),
      background-color 250ms cubic-bezier(0.34, 1.56, 0.64, 1);
    opacity: 0;
    z-index: 1;

    &.visible {
      opacity: 1;
    }
  }

  .sidebar {
    background-color: #222;
    z-index: 1;

    .chats-container {
      &::-webkit-scrollbar {
        display: none;
      }

      a {
        text-decoration: underline;
        word-break: break-all;
      }
    }

    .sidebar-input {
      transition: border-color 200ms ease-out, background-color 100ms ease-out,
        transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1),
        width 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(8px);
      background-color: rgba(30, 30, 30, 0.6);
      border: 1px solid rgba(255, 255, 255, 0.4);
      color: white;
      outline: none;
      border-radius: 999px;
      height: 40px;
      padding: 6px 16px;

      &:hover {
        background-color: rgba(60, 60, 60, 0.7);
      }

      &:focus {
        border-color: rgba(255, 255, 255, 0.7);
        outline: none;
      }

      &::placeholder {
        border-color: rgba(255, 255, 255, 0.6);
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }

  .share-container {
    z-index: 2;

    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    // background-color: rgba(0, 0, 0, 0.8);
    .share-warning {
      width: 100%;
      background-color: red;
    }

    video[data-clipped='false'],
    .image-snapshot {
      object-fit: contain;
      //max-width: 100%;
      //max-height: 100%;
      height: 100%;
      width: 100%;
    }

    video[data-clipped='true'] {
      position: absolute;
      max-width: initial;
    }

    iframe {
      background-color: white;
    }
  }
}

.lobby.horizontal {
  .video-panel-present {
    width: 20%;
    min-width: 100px;
    height: 100%;
    margin: 30px 0px 20px 20px;
  }
}

.video-panel {
  width: 100%;
  height: 100%;

  .debugInfo {
    position: absolute;
    z-index: 3;
    overflow: scroll;
    width: 50%;
    left: 10px;
    bottom: 10px;
  }

  &.video-panel-present {
    height: 20%;

    .video-container {
      height: 100%;
      width: 100%;
    }
  }

  .bubbles {
    width: 100%;
    height: 100%;
    position: relative;
    [bubble] {
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      transition: width 500ms linear, height 500ms linear,
        font-size 500ms linear, left 100ms linear, top 100ms linear;
    }
  }

  .video-container {
    transition: border-color 200ms ease-out, background-color 100ms ease-out,
      transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1),
      width 300ms cubic-bezier(0.34, 1.56, 0.64, 1),
      margin 300ms cubic-bezier(0.34, 1.56, 0.64, 1),
      height 300ms cubic-bezier(0.34, 1.56, 0.64, 1),
      top 300ms cubic-bezier(0.34, 1.56, 0.64, 1),
      left 300ms cubic-bezier(0.34, 1.56, 0.64, 1);

    width: 90%;
    height: 90%;

    .nametag {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, -20px) scale(0.8);
      opacity: 0;
      z-index: 2;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(8px);
      background-color: rgba(30, 30, 30, 0.6);
      padding: 2px 10px;
      word-wrap: none;
      white-space: nowrap;
      border-radius: 999px;
      width: min-content;
      transition: opacity 200ms ease-out,
        transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1),
        width 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
    }
  }

  .video-container:hover,
  .video-container.dragging {
    .video-circle {
      transform: scale(1.1);
    }

    .nametag {
      transform: translate(-50%, 0px) scale(1);
      opacity: 1;
    }
  }
}

.video-circle {
  position: relative;
  //overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  border-radius: 9999px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border-width: 2px;
  object-fit: cover;
  transition: border 200ms ease-out, background-color 100ms ease-out,
    transform 200ms cubic-bezier(0.34, 1.56, 0.64, 1),
    width 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  transition: transform 500ms ease;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.54) 0%, #000000 100%);
  width: 100%;
  height: 100%;
  .emoji-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    transition-property: opacity, transform;
    z-index: 6;
  }
  .video-placeholder {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
  }
  video {
    position: absolute;
    width: auto;
    max-width: none;
    height: 100%;
  }
  .video-inner-circle {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: box-shadow 500ms ease-out;
  }
  &.is-speaking {
    transform: scale(1.05);
    .video-inner-circle {
      box-shadow: 0 0 0px 5px #33d477;
    }
  }
}
.presenter-icon {
  width: 54px !important;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block !important;
  .chakra-button__icon {
    margin: 0 auto !important;
  }
}

video.mirror {
  transform: scaleX(-1);
}
.present-button-menu-wrapper {
  [data-popper-reference-hidden='false'] {
    z-index: 999 !important;
  }
}

.reactionEffect {
  position: absolute;
  z-index: 9999;
  bottom: 0;
  left: 0;
}

emoji-picker {
  height: 350px;
  --num-columns: 7;
  --border-color: none;
  --border-size: 0px;
  --input-border-color: #808080;
  --input-border-radius: 99999px;
  --input-font-color: #808080;
  --outline-color: #ccc;
  --input-placeholder-color: #808080;
}
