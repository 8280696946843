$slide-margin: 1%;
$max-content-size: #{100% - $slide-margin * 2};
$navbar-height: 66px;
$title-height: 15%;

.slide {
  display: block;
  width: $max-content-size;
  height: calc(100% - #{$navbar-height});
  height: 100%;
  font-size: 24pt;
}

.element {
  position: relative;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stretch {
  width: 100%;
  // height: 100%;
}

// Elements
.reveal .slide {
  img,
  iframe,
  video {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }

  ul,
  ol {
    display: block;
  }
}

.element {
  position: relative;

  img,
  iframe,
  video {
    max-width: 100%;
    max-height: 100%;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &.fullscreen {
    position: fixed !important; // override react grid layout
    transform: translateZ(0);
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  .custom-code::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}

.richText {
  width: 100%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex-direction: column;
}

.richText-toolbar {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 3px 3px 5px 6px #ccc;
  height: 2.6rem;
  position: absolute;
  top: -3rem;

  input {
    padding-top: 0;
    position: relative;
    height: 2rem;
  }
}

.editor,
.present {
  .reveal,
  .reveal-viewport {
    position: absolute;

    .slides {
      background-color: #fff;
      border: 1px solid #ccc;
    }
  }
}

// editor-only
.editor {
  .reveal,
  .reveal-viewport {
    background-color: #eee;
    overflow: visible;
  }
}

// Present-only
.present {
  .reveal,
  .reveal-viewport {
    background-color: #000;
  }
}

// Presenting
.present {
  .react-grid-item,
  .react-grid-item.cssTransforms {
    transition-property: none;
  }
}

// Editor

.editor {
  .element {
    min-height: 100px;
    height: 100%;
  }

  // #image-uploader,
  // #image-uploader .uppy-Root {
  //   height: 100%;
  // }

  .element.selected {
    z-index: 5;
  }

  .element .react-resizable-handle {
    z-index: 1;
  }

  .element .clickTarget {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    right: 10px;
  }

  .slides {
    transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }

  .slide:hover .element {
    cursor: pointer;
  }

  .slide .element::after {
    transition: all 300ms cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  .slide .element:hover::after {
    content: '';
    position: absolute;
    width: 4px;
    top: 0px;
    height: 100%;
    left: -16px;
    background-color: #3498db44;
  }
  .slide .element.selected::after {
    content: '';
    position: absolute;
    width: 4px;
    top: 0px;
    height: 100%;
    left: -4px;
    background-color: #3498dbaa;
  }

  .slide .element.selected::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    left: -0px;
    pointer-events: none;
    background-color: #3498db22;
  }

  .elementControls {
    left: -2rem;
    position: absolute;
  }

  .fullscreen .elementControls {
    left: 1rem;
    top: 1rem;
    z-index: 10;

    button {
      box-shadow: 0px 0px 5px 3px #000;
    }
  }
}
