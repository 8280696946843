#slidedoc {
  .ProseMirror > .react-renderer {
    margin: 0;
  }

  &.cursor-mode-input .ProseMirror-focused {
    > .react-renderer {
      &.inSelection .slide-backdrop {
        box-shadow: inset var(--chakra-shadows-outline);
      }

      &:last-child,
      &.inSelection {
        .slide-divider {
          opacity: 1;
        }
      }
    }
  }

  &.cursor-mode-spotlight,
  &.cursor-mode-slide {
    caret-color: transparent;
  }

  .slide-container {
    .slide-content {
      // Handle the divs added by Tiptap
      > [data-node-view-content] {
        width: 100%;
        > div {
          width: 100%;
          display: flex;
          > .react-renderer[contenteditable='false'] {
            width: 100%;
          }
        }
      }
    }

    .column {
      > :first-child {
        // margin-top: 0;
      }

      &:hover {
        // outline: 1px dotted #ddd;
      }

      display: block;
      width: 100%;
      border-radius: 10px;

      > * + *:not(blockquote) {
        margin-top: 1.5rem;
      }

      > * {
        margin: 2rem;
      }
    }
  }

  [data-layout='stretch'] {
    padding: 0;

    .slide-box {
      width: 100%;
    }
  }

  [data-layout='two-col'] {
    .column {
      // border: 1px solid black;
      &.inSelection {
      }

      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 1;
        // margin-left: 1.5rem;
      }
    }
  }
}
