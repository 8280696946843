.outline {
  height: 100vh;

  .navigator {
    padding: 10px;
    font-family: Inter, sans-serif;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    overflow: auto;
    padding-bottom: 70px;

    .slide-node {
      height: min-content;
      padding: 5px 15px;
      position: relative;

      &[data-selected='true'] {
        background: rgba(0, 0, 255, 0.08);
        border-radius: 60px;
      }

      .collapseButton {
        position: absolute;
        left: -5px;
        top: 1px;

        &:hover {
          background: none;
        }

        &.expanded {
          transform: rotate(90deg);
        }
      }
    }

    .drop-inside-indicator {
      transition: background-color 150ms ease-out;
      position: absolute;
      width: 100%;
      height: 80%;
      top: 0px;
      left: 0px;
      border-radius: 4px;
      &.over {
        background-color: rgba(13, 110, 253, 0.25);
      }
    }

    .drop-after-indicator {
      position: absolute;
      bottom: -4px;
      width: 100%;
      height: 8px;
      border-radius: 4px;
      transition: background-color 150ms ease-out;

      &.over {
        background-color: rgba(13, 110, 253, 0.25);
      }
    }
  }

  .editor {
    position: relative;
    overflow: auto;

    .control-panel {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 2;
      width: 300px;
      padding: 0.6em;
      color: var(--main-color);
      justify-content: flex-end;

      .mode-picker {
        .selected {
          background-color: #ffffff;
        }
        button {
          border-radius: 1em;
          background-color: transparent;
          padding: 0.8em;
        }
      }
    }

    .present-controls {
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 300px;
      //background-color: var(--background-color);
      padding: 0.6em;
      color: var(--main-color);
      justify-content: flex-end;
    }
  }

  .editor-code {
    .code-box {
      font-family: monospace;
    }
  }

  .editor-present {
    background-color: var(--background-color);
    color: var(--main-color);
    font-family: var(--main-font);
    font-size: 30px;
    max-width: 100%;

    .slide-body {
      p {
        opacity: 0.25;
        font-size: 0.1em;
        transition: font-size 200ms linear, opacity 200ms linear,
          max-width 200ms linear;
        //white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 15%;
        padding: 0.5em 0;
      }

      p:hover {
        opacity: 0.75;
        font-size: 0.5em;
        white-space: normal;
        max-width: 75%;
      }

      li li {
        opacity: 0.25;
        font-size: 0.1em;
        transition: font-size 200ms linear, opacity 200ms linear;
      }

      li:hover li {
        opacity: 0.75;
        font-size: 0.75em;
      }

      h1,
      li {
        font-size: 1em;
        font-weight: 500;
        //margin: 0.5em;
      }
    }

    .title {
      font-size: 1.5em;
      font-weight: 700;
      text-align: center;
      margin: 0;
      margin-bottom: 0.5em;
    }

    .media-grid {
      display: flex;
      margin-left: -30px; /* gutter size offset */
      width: auto;

      .media-item {
        cursor: pointer;
      }
    }
    .media-grid-column {
      padding-left: 30px; /* gutter size */
      background-clip: padding-box;
    }
  }

  .editor-write {
    background-color: var(--background-color);
    color: var(--main-color);
    font-family: var(--main-font);
    overflow: auto;

    .slide-card {
      border-bottom: 1px solid #e0e0e0;
    }

    .embedCard {
      .fslightbox-toolbar {
        display: none;
      }
    }

    .title {
      font-size: 24px;
      margin-left: 2px;
    }

    .emoji {
      font-size: 22px;
      width: 22px;
      height: 22px;
      position: relative;
      top: -10px;
      left: -10px;
      line-height: 1.4;
      text-align: center;
    }

    .media {
      background-color: rgba(0, 0, 0, 0.02);
      .uppy-Dashboard-inner {
        //background: none;
        border: none;
        color: var(--main-color);
      }

      .media-item {
        position: relative;
        max-height: 400px;
        min-height: 100px;
        overflow: hidden;
        cursor: pointer;

        .delete-button {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
        }

        &:hover {
          .delete-button {
            opacity: 1;
          }
        }
      }
    }

    .markdown-editor > div {
      background-color: var(--background-color);
    }

    .ProseMirror {
      font-family: var(--main-font);
      // border-left: 1px solid #ccc;
      padding-left: 24px;
      font-size: 15px;
      font-weight: 400;
      color: var(--main-color);

      p {
        margin-bottom: 1em;
      }

      a {
        color: var(--link-color);
      }

      h1::before {
        content: '' !important;
      }

      h1,
      & > ul > li {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.4;
        color: var(--main-color);
        margin: 1em;
        margin-left: -0px;

        &::before {
          // content: '❯';
          content: '•';
          position: absolute;
          left: -10px;
          top: 0px;
          font-size: 1em;
          background-color: var(--background-color);
          width: 30px;
          height: 30px;
          line-height: 1.4;
          text-align: center;
          font-weight: 400;
          font-family: var(--main-font);
          color: var(--main-color);
        }
      }

      & > ul > li {
        &::marker {
          content: '';
        }
        margin-left: -44px;
        padding-left: 25px;

        & > ul {
          margin-top: 0.5em;
        }

        li {
          font-size: 15px;
          color: #666;
          &::marker {
            content: '•';
          }
          margin-left: auto;
          padding-left: 10px;
        }
      }
    }
  }
}
