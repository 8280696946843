#focus-mode {
  .ProseMirror {
    > *:not(div.react-renderer) + *:not(blockquote) {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 1.5rem;
    }
  }
}

.gamma-editor {
  /* Basic editor styles */
  .ProseMirror {
    // padding: 1em;
    outline-width: 0px;

    > * + *:not(blockquote) {
      margin-top: 1.5rem;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    h1.title {
      margin-top: 0;
      font-size: 3em;
    }

    a {
      color: var(--chakra-colors-blue-500);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.card-link {
        color: var(--chakra-colors-blue-500);
        background-color: var(--chakra-colors-blue-50);
        border-radius: 0.3rem;
        padding: 0.1rem 0.3rem;
      }
      &.card-block {
        color: black;

        > div {
          margin-bottom: 1.5rem;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem !important;
      border-radius: 3px;
      font-family: var(--chakra-fonts-mono);
      font-size: var(--chakra-fontSizes-sm);
      border-radius: var(--chakra-radii-sm);
      background: var(--chakra-colors-gray-100);
      color: var(--chakra-colors-gray-800);
      margin-top: 1.5rem;

      code {
        color: inherit;
        background: none;
      }
    }

    img:not(.chakra-avatar__img) {
      max-width: 100%;
      height: auto;
      border-radius: 3px;
      transition: box-shadow 150ms ease-out;
      padding-top: 0px !important;
    }

    .ProseMirror-selectednode,
    .interactive-node.selected,
    img.inSelection,
    .tableWrapper.inSelection,
    .inSelection .canva .full-width-slide,
    .inSelection .embed {
      box-shadow: var(--chakra-shadows-outline);
    }

    .image.placeholder {
      display: inline-block;
      background-color: white;
      width: 100%;
      min-height: 1rem;

      img {
        opacity: 50%;
      }
    }

    .embed-wrapper img {
      margin-top: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
      > * + * {
        padding-top: 1.5rem;
        color: var(--chakra-colors-gray-400);
      }
    }

    hr:not(.chakra-menu__divider) {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    ul[data-type='taskList'] {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;

        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
        }
      }
    }

    .mention {
      color: #a975ff;
      background-color: rgba(#a975ff, 0.1);
      border-radius: 0.3rem;
      padding: 0.1rem 0.3rem;
    }

    .annotation {
      background: #fee9ae;

      // This doesnt work, gotta make our own logic to mark it active
      &.isActive {
        background: #fcc934;
      }
    }

    /* Placeholder (on every new line) */
    p.is-empty::before {
      content: attr(data-placeholder);
      float: left;
      color: #ced4da;
      pointer-events: none;
      height: 0;
    }

    // Title placeholder
    h1.is-empty::after {
      content: attr(placeholder);
      position: absolute;
      top: 0;
      color: #ced4da;
      pointer-events: none;
      height: 0;
    }

    .collaboration-cursor__caret {
      position: relative;
      margin-left: -1px;
      margin-right: -1px;
      border-left: 1px solid #0d0d0d;
      border-right: 1px solid #0d0d0d;
      word-break: normal;
      pointer-events: none;
    }

    /* Render the username above the caret */
    .collaboration-cursor__label {
      position: absolute;
      top: -1.4em;
      left: -1px;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      user-select: none;
      color: #0d0d0d;
      padding: 0.1rem 0.3rem;
      border-radius: 3px 3px 3px 0;
      white-space: nowrap;
    }

    // Tables
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }
  }
}

.tippy-content {
  .items {
    position: relative;
    border-radius: 0.25rem;
    background: white;
    color: rgba(black, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;

    &.is-selected,
    &:hover {
      color: #a975ff;
      background: rgba(#a975ff, 0.1);
    }
  }
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
